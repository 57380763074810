<template>

  <header class="overflow-hidden d-flex align-items-center">
    <img src="assets/header.webp" class="bg-parallax parallax" data-speed="-0.3">
    <div class="opaque-background"></div>

    <div class="container position-relative text-white">
      <div class="row">
        <div class="col-md-5">
          <h1 class="display-3">Servizio Giornali</h1>
          <p class="lead">
            Un grande cambiamento nasce dalla condivisione dello stesso sogno
          </p>
        </div>
      </div>
    </div>

  </header>

</template>

<script>
export default {
  name: "header"
}
</script>