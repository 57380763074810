<template>
  <div>

    <section id="azienda">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 pe-md-5">
            <img src="assets/img/truck.webp" class="img-fluid rounded">
          </div>
          <div class="col-md-6 border-md-start border-primary border-md-3 ps-md-5 py-4 py-md-3">
            <h2 class="mb-3">L' Azienda</h2>
            <p class="mb-0">
              L’azienda di trasporti di Terenzio Pesce nasce a Genova nel 1940. <br>
              La sua storia cambia poco dopo la fine della guerra, quando l’incontro fra il suo fondatore ed il
              responsabile della diffusione del Corriere della Sera da vita ad un sodalizio che continua ancora oggi.
              <br>
              Attraverso tre generazioni, l’impresa individuale ha esteso l’attività di trasporto dei più importanti
              quotidiani a tutto il territorio nazionale ed oggi, oltre settant’anni dopo, il gruppo Servizio Giornali
              si pone come punto di riferimento nel mercato della distribuzione della carta stampata. <br>
              L’azienda si propone come unico interlocutore operativo per i propri clienti, organizzando il trasporto da
              origine a destino secondo criteri di eccellenza: precisione, puntualità, dedizione per il lavoro,
              elasticità rispetto alle esigenze del committente sono ciò che rende il gruppo Servizio Giornali non
              soltanto l’erede di una tradizione ma anche una realtà aperta al futuro.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="contatti" class="contact-section position-relative overflow-hidden">
      <img src="assets/img/deposito.jpg"
           class="bg-parallax parallax custom-parallax"
           data-speed="-0.3"
           data-margint="400"
           alt="">
      <div class="opaque-background"></div>

      <div class="container position-relative text-white">
        <div class="row justify-content-between align-items-center">

          <div class="col-md-5">
            <h2 class="mb-3">Contattaci</h2>
            <p class="lead">Hai bisogno di maggiori informazioni? Inviaci un messaggio.</p>

            <div class="row pt-4">

              <div class="col-md-6">
                <div class="d-flex align-items-start py-3">
                  <map-pin-icon size="1.7x" class="me-2"></map-pin-icon>
                  <p class="mb-0">
                    <strong>Indirizzo: </strong> <br>
                    <span class="text-white-50">Via T. Mamiani 40/r , 16149, Genova</span>
                  </p>
                </div>
                <div class="d-flex align-items-start py-3">
                  <mail-icon size="1.7x" class="me-2"></mail-icon>
                  <p class="mb-0"><strong>Mail: </strong> <br>
                    <span class="text-white-50">
                      {{
                        '&#115;&#101;&#114;&#118;&#105;&#122;&#105;&#111;&#103;&#105;&#111;&#114;&#110;&#097;&#108;' +
                        '&#105;&#064;&#121;&#097;&#104;&#111;&#111;&#046;&#105;&#116;'
                      }}
                    </span>
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="d-flex align-items-start py-3">
                  <phone-icon size="1.7x" class="me-2"></phone-icon>
                  <p class="mb-0"><strong>Telefono: </strong> <br> <span class="text-white-50">0805323288</span></p>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-6">

            <div class="alert alert-success bg-success text-white border-0 alert-dismissible fade show" role="alert"
                 v-if="server.message != null && !server.error">
              {{ server.message }}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-danger bg-danger text-white border-0 alert-dismissible fade show" role="alert"
                 v-if="server.message != null && server.error">
              {{ server.message }}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            <div class="form-group row py-2">
              <div class="col-6">
                <label for="contact_name">Nome</label>
                <input type="text" id="contact_name" class="form-control" v-model="contact_form.name">
              </div>
              <div class="col-6">
                <label for="contact_surname">Cognome</label>
                <input type="text" id="contact_surname" class="form-control" v-model="contact_form.surname">
              </div>
            </div>
            <div class="form-group py-2">
              <label for="contact_email">Email</label>
              <input type="email" id="contact_email" class="form-control" v-model="contact_form.email">
            </div>
            <div class="form-group py-2">
                <label for="contact_message">Messaggio</label>
                <textarea id="contact_message"
                          cols="10"
                          rows="10"
                          class="form-control"
                          style="max-height: 110px"
                          v-model="contact_form.message" />
            </div>
            <button class="btn btn-primary w-100 mt-3 py-2" v-on:click="set_recaptcha_token()">Invia Messaggio</button>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { MapPinIcon, PhoneIcon, MailIcon } from 'vue-feather-icons'

export default {
  name: "index",
  components: { MapPinIcon, PhoneIcon, MailIcon },
  data() {
    return {
      contact_form: {
        name: null,
        surname: null,
        email: null,
        message: null,
        g_recaptcha_token: null
      },
      server: {
        message: null,
        error: false
      }
    }
  },
  methods: {
    set_recaptcha_token() {
      this.$root.recaptcha()
        .then(result => {
          this.contact_form.g_recaptcha_token = result
          this.send_contact_form();
        });
    },
    async send_contact_form() {

      try {

        let response = (await this.axios.post('https://gestionale.serviziogiornali.it/api/contact_form', this.contact_form))
        this.server.message = response.data.message
        this.server.error = false

        this.contact_form.name = null
        this.contact_form.surname = null
        this.contact_form.email = null
        this.contact_form.message = null
        this.contact_form.g_recaptcha_token = null

      } catch (error) {

        if (error.response.status === 422) {
          this.server.message = error.response.data.message
          this.server.error = true
        } else {
          this.server.message = "Spiacenti! Si è verificato un errore, se l'errore persiste contattaci usando uno dei recapiti di fianco."
          this.server.error = true
        }

      }
    }
  }
}
</script>

<style scoped>
  input, textarea {
    margin-top: 5px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    border: 0;
  }

  .custom-parallax {
    height: auto;
    min-height: 100%;
  }
</style>