<template>
  <footer class="py-5">

    <div class="container">
      <div class="row justify-content-between">
        <div class="col-auto">
          <p class="mb-0">Servizio Giornali S.R.L. 2023 © Tutti i diritti riservati.</p>
          <div class="mt-3">
            <button class="btn btn-light me-3">Privacy Policy</button>
            <button class="btn btn-light">Termini e condizioni</button>
          </div>
        </div>
        <div class="col-auto">
          <p class="mb-0">
            Made with
            <heart-icon size="1.2x" class="text-danger"></heart-icon>
            by
            <a href="https://www.beexel.it" class="text-warning" target="_blank">Beexel</a>
          </p>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { HeartIcon } from "vue-feather-icons"

export default {
  name: "footer",
  components: { HeartIcon }
}
</script>